import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { URL } from "APIUtils";

export default function ClientComponent() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const handleModalToggle = () => {
    // setShowModal(false);
    window.location.href = "sizzl://";
  };

  // const generateOneidToken = async (authorizationCode, authorizationToken) => {
  //   console.log({
  //     authorizationCode: authorizationCode,
  //     token: authorizationToken,
  //   });
  //   const url = "https://controller.sandbox.myoneid.co.uk/token";
  //   // const url = "https://controller.myoneid.co.uk/token";

  //   const headers = {
  //     Accept: "*/*",

  //     "Content-Type": "application/x-www-form-urlencoded",
  //     Authorization:
  //       // "Basic YzI2YTk2MzAtMDJkMC00ZmU4LTk4NzQtZWIyNjYxODk5NTYzOmE3ODlkMzg0LWQ0NjMtNDJlYi04OWY0LTYzZTVkNWQ2YzBlYg==",
  //       "Basic ZTRlZmU4MDctOGE3Ni00NjYxLWJkNDYtNWIzN2M1NDNmYmJlOmVmYWMwMmVmLTBjMWMtNDMzMi04MDljLTA5NzYxMzAwMTcyYQ==",
  //   };

  //   const body = new URLSearchParams({
  //     grant_type: "authorization_code",
  //     code: authorizationCode,
  //     state: authorizationToken,
  //   });

  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: headers,
  //       body: body.toString(),
  //     });

  //     const data = await response.json();
  //     console.log("Response data:", data);
  //     if (data?.access_token) {
  //       checkOneId(data?.access_token);
  //     } else {
  //       alert("Something went wrong");
  //     }
  //     // Handle the response data as needed
  //   } catch (error) {
  //     console.error("Error during fetch:", error);
  //   }
  // };

  // const checkOneId = async (authorizationCode, authorizationToken) => {
  //   // update if true

  //   console.log("Updating user");

  //   var dataUser = JSON.stringify({
  //     authorizationCode: authorizationCode,
  //     authorizationToken: authorizationToken,
  //     isOneIdComplete: true,
  //   });

  //   var config = {
  //     method: "post",
  //     url: `${URL}/user/update-profile-data-oneid`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //     },
  //     data: dataUser,
  //   };

  //   try {
  //     const response = await axios(config);
  //     console.log("completed", response.data);

  //     setShowModal(true);
  //   } catch (error) {
  //     setShowModal(false);

  //     console.log(error);
  //     alert("An error occurred while updating the user profile");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const callUpdateUser = async () => {
      const searchParams = new URLSearchParams(location.search);
      const authCode = searchParams.get("code");
      const authToken = searchParams.get("state");
      console.log("test authcode and token", {
        searchParams,
        authCode,
        authToken,
      });
      const error = searchParams.get("error");
      console.log({ authCode, authToken, error });

      if (error) {
        alert("Error occurred while verifying user");
        setLoading(false);
      } else {
        window.location.href = `sizzl://IsOneIdVerified?code=${authCode}&state=${authToken}`;

        // checkOneId(authCode, authToken);
      }
    };

    callUpdateUser();
  }, [location.search]);

  if (loading) {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   height,
          fontSize: 30,
        }}
      >
        Loading...
      </p>
    );
  }

  return (
    <>
      {showModal && (
        <>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   height,
              fontSize: 30,
            }}
          >
            Success Return to mobile to Continue
          </p>
          <div
            id="successModal"
            tabIndex={-1}
            aria-hidden={!showModal}
            className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full"
          >
            {/* Modal content */}
            <>
              <>
                {/* Main modal */}
                {showModal && (
                  <div
                    id="successModal"
                    tabIndex={-1}
                    aria-hidden={!showModal}
                    className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full"
                  >
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                      {/* Modal content */}
                      <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                        <button
                          type="button"
                          className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={handleModalToggle}
                          data-modal-toggle="successModal"
                        >
                          <svg
                            aria-hidden={true}
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                        <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                          <svg
                            aria-hidden={true}
                            className="w-8 h-8 text-green-500 dark:text-green-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Success</span>
                        </div>
                        <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                          Successfully verified user.
                        </p>
                        <button
                          data-modal-toggle="successModal"
                          type="button"
                          style={{
                            backgroundColor: "green",
                          }}
                          className="py-2 px-3 text-sm font-medium text-center text-white rounded-lg  hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900"
                          onClick={handleModalToggle}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </>

            {/* ... (keep your existing modal code here) ... */}
          </div>
        </>
      )}
    </>
  );
}
